<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
              />
              <!-- <b-button variant="primary" @click="onSubmit">
                <span class="text-nowrap">{{ $t("newUser") }}</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'UserDetail', params: { id: data.item.id } }">
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.name }}
            </span>
          </b-link>
        </template>

        <template #cell(active)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox v-model="data.item.active" disabled />
          </div>
        </template>

        <template #cell(details)="data">
          <b-link
            class="d-block"
            :to="{ name: 'UserDetail', params: { id: data.item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
            <small class="align-middle ml-50 text-dark">
              {{ $t("detail") }}
            </small>
          </b-link>
        </template>

        <template #cell(delete)="data">
          <b-link @click="deleteUser(data.item.id)">
            <feather-icon class="text-danger" icon="Trash2Icon" />
            <small class="align-middle ml-50 text-dark">
              {{ $t("delete") }}
            </small>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUserList from "../useUserList";
import UserListAddNew from "./UserListAddNew.vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import store from "@/store";

export default {
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BLink,
    BPagination,
    BFormCheckbox,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },
  async created() {
    // this.typeOptions = [];
    // var types = [];
    // var { data } = await this.$http.get("/Users/Types");
    // data.map((x) =>
    //   this.typeOptions.push({ label: x.userType, value: x.userType })
    // );
  },
  setup({ emit }) {
    const isAddNewUserSidebarActive = ref(false);
    const userData = getUserData();
    const onSubmit = () => {
      store.dispatch("usersModule/addUser", userData).then(() => {
        refetchData();
      });
    };
    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteUser,
    } = useUserList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      onSubmit,
      deleteUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
