<template>
  <div><user-list action-tpye="dsada" /></div>
</template>

<script>
import UserList from "./UserList/UserList.vue";
export default {
  components: {
    UserList,
  },
  mounted() {},
};
</script>

<style></style>
